<template>
  <div class="contacts content pt-sm-5 bg-custom">
    <div class="container-fluid pt-5 mt-5">
      <div class="row p-5 text-light">
        <!-- Contact Title -->
        <h1 class="title text-start fw-bold">お問い合わせ</h1>
        <!-- Column -->
        <div class="col-sm text-start col-md-7">
          <div class="row py-5">
            <!-- Contact Form -->
            <form @submit.prevent="valide">
              <!-- Full Name -->
              <div class="col-sm-9 mb-3">
                <label for="exampleFormControlInput0" class="form-label"
                  >お名前</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput0"
                  placeholder="お名前"
                  v-model="fullname.fnvalue"
                />
                <small v-show="fullname.fnerror" class="form-text text-danger"
                  >Name required.</small
                >
              </div>
              <!-- Email Address -->
              <div class="col-sm-9 mb-3">
                <label for="exampleFormControlInput1" class="form-label"
                  >メールアドレス</label
                >
                <input
                  type="email"
                  class="form-control"
                  id="exampleFormControlInput1"
                  placeholder="name@example.com"
                  v-model="email.emailvalue"
                  required
                />
                <small v-show="email.emailerror" class="form-text text-danger"
                  >Incorrect Email Address.</small
                >
              </div>
              <!-- Subject -->
              <div class="col-sm-9 mb-3">
                <label for="exampleFormControlInput2" class="form-label"
                  >件名</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="exampleFormControlInput2"
                  placeholder="件名"
                  v-model="subject.sjvalue"
                />
                <small v-show="subject.sjerror" class="form-text text-danger"
                  >Subject required.</small
                >
              </div>
              <!-- Message -->
              <div class="col-sm-9 mb-3">
                <label for="exampleFormControlTextarea1" class="form-label"
                  >お問い合わせの詳細</label
                >
                <textarea
                  class="form-control"
                  id="exampleFormControlTextarea1"
                  rows="5"
                  placeholder="お問い合わせの内容"
                  v-model="message.msgvalue"
                ></textarea>
                <small v-show="message.msgerror" class="form-text text-danger"
                  >Message required.</small
                >
              </div>
              <!-- Submit -->
              <button type="submit" class="btn btn-secondary" @click="submit">
                <fa :icon="['fas', 'save']" class="me-2" />送信
              </button>
            </form>
          </div>
        </div>
        <!-- 
        <div class="col-sm pb-3 col-md-5">
          <iframe
          srcc="" 
            width="100%"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
        -->
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from "vue";
import axios from "axios";
import _ from "lodash";
export default {
  name: "Contact",
  methods: {
    submit() {
      console.log(this.ready);
    },
  },
  data() {
    return {
      ready: 0,
    };
  },
  setup() {
    //form values
    const formvalidation = reactive({
      fullname: {
        fnvalue: "",
        fnerror: false,
      },
      email: {
        emailvalue: "",
        emailerror: false,
      },
      subject: {
        sjvalue: "",
        sjerror: false,
      },
      message: {
        msgvalue: "",
        msgerror: false,
      },
      ready: 0,
    });
    //regex validation
    const mailformat = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    watch(
      () => _.cloneDeep(formvalidation.email),
      (newvalue) => {
        if (!mailformat.test(newvalue.emailvalue) && newvalue.emailvalue !== "")
          formvalidation.email.emailerror = true;
        else formvalidation.email.emailerror = false;
      },
      { deep: true }
    );
    //api
    async function send(fm) {
      const url = "http://localhost/api/send.php";
      const params = {
        name: fm.fullname.fnvalue,
        email: fm.email.emailvalue,
        subject: fm.subject.sjvalue,
        message: fm.message.msgvalue,
      };
      console.log(fm.fullname);
      console.log(params);
      return await axios
        .post(url, params)
        .then(function (res) {
          return res.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    //form submit
    function valide() {
      formvalidation.ready = true;
      if (formvalidation.fullname.fnvalue === "") {
        formvalidation.fullname.fnerror = true;
        formvalidation.ready = false;
      } else {
        formvalidation.fullname.fnerror = false;
      }
      if (formvalidation.email.emailvalue === "") {
        formvalidation.email.emailerror = true;
        formvalidation.ready = false;
      } else {
        formvalidation.email.emailerror = false;
      }
      if (formvalidation.subject.sjvalue === "") {
        formvalidation.subject.sjerror = true;
        formvalidation.ready = false;
      } else {
        formvalidation.subject.sjerror = false;
      }
      if (formvalidation.message.msgvalue === "") {
        formvalidation.message.msgerror = true;
        formvalidation.ready = false;
      } else {
        formvalidation.message.msgerror = false;
      }
      if (formvalidation.ready) {
        send(formvalidation);
      }
    }
    return {
      ...toRefs(formvalidation),
      valide,
    };
  },
};
</script>

<style lang="scss" scoped>
.contacts {
  min-height: 900px;
  p,
  h1,
  h4 {
    color: #333;
  }
}
</style>
